<template>
  <svg width="73" height="71" viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.7343 25.8116C59.9395 28.0184 63.1728 28.5729 65.892 27.48L71.733 33.3178C72.9245 34.5141 72.9261 36.4509 71.7266 37.6505L39.2527 70.105C38.0515 71.2997 36.1163 71.2965 34.9184 70.105L29.0887 64.2656C30.1751 61.5431 29.6222 58.313 27.4203 56.1063C25.2103 53.8995 21.9818 53.3466 19.261 54.4379L15.4473 50.6226C14.251 49.4311 14.2477 47.4895 15.4473 46.2899L47.9163 13.8306C49.1111 12.6342 51.0462 12.6326 52.2474 13.8338L56.0643 17.6507C54.9698 20.3716 55.5292 23.6017 57.7343 25.8116Z"
      fill="#D09CFF"
    />
    <path
      d="M23.2471 42.82L19.7729 46.2926C18.5781 47.4873 18.5749 49.4273 19.768 50.6253L20.6442 51.4999C23.7094 50.6835 27.1093 51.4708 29.5068 53.8731C31.9124 56.2755 32.6932 59.6753 31.8784 62.7341L34.9145 65.7783C36.1125 66.973 38.0525 66.973 39.2504 65.775L42.7893 62.2394L23.2471 42.82ZM45.106 59.9243L61.528 43.5119L41.981 24.0926L25.5654 40.505L45.106 59.9243ZM48.3231 37.3023C51.9525 40.9043 52.7609 45.9612 50.1354 48.6012C47.5051 51.2444 42.4369 50.462 38.8171 46.8633C35.1974 43.2614 34.3907 38.2012 37.0162 35.5612C39.6433 32.926 44.7034 33.702 48.3231 37.3023ZM67.3997 33.3189L64.3588 30.2779C61.2952 31.0911 57.8954 30.3119 55.4946 27.9047C53.089 25.5056 52.3001 22.0992 53.1181 19.0389L52.2435 18.1562C51.0472 16.968 49.1072 16.968 47.9141 18.1611L44.2993 21.7759L63.8479 41.1937L67.3981 37.6451C68.5945 36.4536 68.5945 34.5152 67.3997 33.3189Z"
      fill="#AD5CF6"
    />
    <path
      d="M34.8798 18.1492L25.5182 21.6439L22.0225 33.1986C21.8878 33.6442 21.4786 33.9497 21.0168 33.9497C20.5549 33.9497 20.1457 33.6442 20.0111 33.1986L16.5164 21.6439L7.15374 18.1492C6.74031 17.9943 6.4668 17.5986 6.4668 17.154C6.4668 16.7106 6.74031 16.3127 7.15374 16.1589L16.5111 12.6652L20.0079 0.75835C20.1405 0.308514 20.5507 -0.000217438 21.0168 -0.000217438C21.4817 -0.000217438 21.8931 0.308514 22.0256 0.759411L25.5224 12.6663L34.8808 16.1599C35.2922 16.3127 35.5667 16.7095 35.5667 17.154C35.5667 17.5975 35.2922 17.9943 34.8798 18.1492Z"
      fill="#FFAC33"
    />
    <path
      d="M13.8713 7.38598L11.4664 8.27574L10.5311 11.7059C10.4084 12.1591 9.99791 12.473 9.52919 12.473C9.06047 12.473 8.64996 12.1591 8.52628 11.7069L7.59093 8.27678L5.18706 7.38702C4.77863 7.23631 4.50841 6.84652 4.50841 6.41204C4.50841 5.97755 4.77863 5.58777 5.18706 5.43705L7.57845 4.55041L8.52108 0.785591C8.63748 0.324085 9.05216 -0.000217438 9.52919 -0.000217438C10.0062 -0.000217438 10.4209 0.324085 10.5373 0.786629L11.4799 4.55145L13.8713 5.43809C14.2798 5.58777 14.55 5.97755 14.55 6.41204C14.55 6.84652 14.2798 7.23631 13.8713 7.38598ZM9.3629 29.9032L6.90604 30.8127L5.99563 33.271C5.84493 33.6795 5.45624 33.9497 5.02078 33.9497C4.58636 33.9497 4.19767 33.6795 4.04593 33.271L3.13656 30.8127L0.678652 29.9032C0.270214 29.7514 0 29.3627 0 28.9282C0 28.4937 0.270214 28.1039 0.678652 27.9532L3.13656 27.0437L4.04593 24.5855C4.19767 24.177 4.58636 23.9067 5.02078 23.9067C5.4552 23.9067 5.84389 24.177 5.99563 24.5855L6.905 27.0437L9.3629 27.9532C9.77134 28.105 10.0416 28.4937 10.0416 28.9282C10.0416 29.3627 9.77134 29.7514 9.3629 29.9032Z"
      fill="#FFCC4D"
    />
  </svg>
</template>

<template>
  <NotificationCard ref="offer-card">
    <div class="coupon-notification flex-col h-left" @click="goToOffers">
      <CouponNotificationIcon class="icon" />
      <span class="promotion">PROMOTION</span>
      <div class="description flex-col">
        <span class="title">Appliquée lors du paiement</span>
        <span class="text">
          Choisissez d'abord votre abonnement pour que l'offre s'applique directement dans votre panier.
        </span>
      </div>
    </div>
  </NotificationCard>
</template>

<script>
import NotificationCard from './NotificationCard.vue'
import CouponNotificationIcon from './Icons/CouponNotificationIcon.vue'

export default {
  components: {
    NotificationCard,
    CouponNotificationIcon
  },
  methods: {
    goToOffers() {
      this.$router.push('/offres')
    },
  }
}
</script>

<style lang="less" scoped>
.coupon-notification {
  width: 100%;
  padding: 16px 12px 12px 12px;
  position: relative;
  cursor: pointer;

  .icon {
    height: 41px;
    position: absolute;
    left: -4px;
    top: 8px;
  }

  .promotion {
    font-size: 14px;
    color: var(--ds-color-white-100);
    padding: 3px 13px 3px 29px;
    text-decoration: none;
    font-weight: var(--ds-weight-semi-bold);
    background-color: var(--ds-color-primary-100);
    border-radius: 20px;
    margin: 5px 0 0 16px;
  }

  .description {
    font-size: 14px;
    color: #272727;
    text-align: left;
    margin: 12px 0 0 0;

    .title {
      font-weight: 800;
    }
    .text {
      font-weight: 400;
    }
  }
}

@media @bp-desktop {
  .coupon-notification {
    padding: 16px 12px 24px 12px;
    align-items: center !important;

    .icon {
      height: 71px;
      position: relative;
      left: -10px;
      top: 0;
    }

    .promotion {
      margin: 20px 0 0 0;
      padding: 3px 50px;
    }

    .description {
      margin: 20px 0 0 0;
      text-align: center;
    }
  }
}
</style>

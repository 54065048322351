import Keycloak from 'keycloak-js'

import { useAuthStore } from '../stores/auth'

const sessionStorageStartAuthDateKey = 'start-auth-date'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()

  let keycloak = new Keycloak(`https://${location.hostname}/keycloak-phoenix.json`)
  window.keycloak = keycloak
  let keycloakInitOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    redirectUri: window.location.href,
  }

  const refreshAccessTokenCallback = async () => {
    await keycloak.updateToken(70)

    return keycloak.token
  }

  keycloak.onAuthLogout = () => {
    window.removeEventListener('focus', refreshAccessTokenCallback)
    useAuthStore().$reset()
  }

  // Refresh Token
  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(config.public.kcTokenTtl)
      .then(() => {})
      .catch(function () {
        // eslint-disable-next-line no-console
        console.log('KC: Failed to refresh the token, or the session has expired')
      })
  }

  function getNowTimeStamp() {
    return Math.floor(Date.now() / 1000)
  }

  const startAuthDateKey = sessionStorage.getItem(sessionStorageStartAuthDateKey)
  let userSignInLessThant5min = false

  if (startAuthDateKey) {
    userSignInLessThant5min = getNowTimeStamp() - parseInt(startAuthDateKey) < 60 * 5
  }
  sessionStorage.removeItem(sessionStorageStartAuthDateKey)

  await new Promise((resolve) => {
    keycloak.init(keycloakInitOptions).then(async (authenticated) => {
      authStore.setLoggedIn(authenticated)

      if (authenticated) {
        const token = keycloak.tokenParsed
        const user = token.rl_profile
        const userRegisteredLessThant30s = token.auth_time - user.registered < 30

        authStore.setUser({
          user,
          userRegisteredLessThant30s,
          userSignInLessThant5min,
        })

        window.addEventListener('focus', refreshAccessTokenCallback)
      }

      resolve()
    })
  })

  return {}
})

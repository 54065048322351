import { hooksFetch } from '../core/api'

export const useCmsApi = () => ({
  getCommonComponents: async () => await hooksFetch('/v1/cms/common-components'),
  getPageArticleOrChildCategoryBySlug: async (parentArticleCategorySlug, articleOrChildCategorySlug, query) =>
    await hooksFetch(
      `/v1/cms/page/article-or-child-category/${parentArticleCategorySlug}/${articleOrChildCategorySlug}`,
      { query },
    ),
  getPageChildCategoryBySlug: async (parentCategorySlug, childCategorySlug, page, query) =>
    await hooksFetch(`/v1/cms/page/child-category/${parentCategorySlug}/${childCategorySlug}/${page}`, {
      query,
    }),
  getPageParentCategoryBySlug: async (parentCategorySlug, query) =>
    await hooksFetch(`/v1/cms/page/parent-category/${parentCategorySlug}`, { query }),
  getLandingGestionArticles: async () => await hooksFetch('/v1/cms/articles/landing-gestion'),
  getLandingSimulationArticles: async () => await hooksFetch('/v1/cms/articles/landing-simulation'),
})

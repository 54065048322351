<template>
  <div
    class="item"
    :class="{ active: isActive }"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <cms-components-shared-link
      v-if="item.columns?.length === 0 && item.link"
      :link="item"
      type="no-style"
    >
      {{ item.text }}
    </cms-components-shared-link>
    <template v-else>
      {{ item.text }}
      <span class="chevron"><uikit-chevron-icon :type="isActive ? 'up' : 'down'" /></span>
    </template>
  </div>
</template>

<script setup>
import { UikitChevronIcon } from '@hz/ui-kit'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
})

const activeMenuId = inject('activeMenuId', null)
const activeMenu = inject('activeMenu')

const isActive = computed(() => {
  return activeMenuId.value === props.item.id
})

const handleMouseOver = () => {
  activeMenu(props.item.id, true)
}

const handleMouseLeave = () => {
  activeMenu(props.item.id, false)
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--ds-space-sm);

  color: var(--ds-color-gray-100);
  font-size: var(--ds-font-md);
  font-style: normal;
  font-weight: var(--ds-weight-medium);

  transition: all 0.3s ease;

  text-wrap: nowrap;

  cursor: pointer;

  &.active {
    opacity: 0.7;
  }

  :deep(.cms-link) {
    text-wrap: nowrap;
  }

  .chevron {
    :deep(svg) {
      transition: all 0.3s ease;
      margin-bottom: 2px;
    }
  }
}
</style>

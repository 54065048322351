import { hooksFetch, hooksFetchPatch } from '../core/api'

const BASE_PATH = '/v1/users/me/action-history'

export function useUserActionHistoryApi() {
  return {
    get: async () => hooksFetch(BASE_PATH),
    flagActionAsDone: async (action) => hooksFetchPatch(`${BASE_PATH}/${action}`, {}),
  }
}

<template>
  <div class="card notification-card" :ref="refName">
    <NotificationCloseIcon class="close" @click="handleClose" />
    <slot />
  </div>
</template>

<script>
import NotificationCloseIcon from './Icons/NotificationCloseIcon.vue'
import { useNotificationStore } from '../../stores/notification'

export default {
  components: {
    NotificationCloseIcon
  },
  methods: {
    handleClose() {
      useNotificationStore().remove(this.$refs[this.refName].id)
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs[this.refName].classList.add('animate')
    }, 50)
  },
  data: function() {
    return {
      refName: null,
    }
  },
  computed: {
    getRefName() {
      let cardCount = 0
      const nodeList = document.querySelectorAll('.notification-card')

      if (nodeList && nodeList.length) cardCount = nodeList.length

      return 'card-' + cardCount.toString()
    },
  },
  beforeMount() {
    this.refName = this.getRefName
  },
}
</script>

<style lang="less" scoped>
.notification-card {
  width: ~'calc(100vw - 40px)';
  min-height: 36px;
  background-color: var(--ds-color-white-100);
  border-radius: 9px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
  border: 1px solid #7E8085;

  &:not(:last-child) {
    margin-top: 10px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    z-index: 10;
  }

  opacity: 0;
  transform: translateY(-100%);
  transition: all 350ms ease;
  will-change: transform, opacity;

  &.animate {
    opacity: 1;
    transform: translateY(0);
    transition: all 350ms ease;
    will-change: transform, opacity;
  }
}

@media @bp-desktop {
  .notification-card {
    width: 336px;

    transform: translateX(100%);
    &.animate {
    transform: translateX(0);
    }
  }
}
</style>

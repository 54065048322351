export function getImageFromFormatOrDefault(image, format) {
  return image.formats && image.formats[format]
    ? image.formats[format].url
    : image.url
}

export function replaceBucketByCdn(text) {
  const config = useRuntimeConfig()
  const domainToReplace = `https://s3.eu-west-3.amazonaws.com/${config.public.hzCmsBucketName}/`
  const replaceByDomain = `${config.public.hzCmsCdnUrl}/`

  return text.replaceAll(domainToReplace, replaceByDomain)
}

export function getResponsiveAttributes(responsiveBreakPoints, cmsImage) {
  const attributes = {}
  if (responsiveBreakPoints.length) {
    let allFormatAvailable = true
    for(let i = 0; i < responsiveBreakPoints.length; i++) {
      if (!cmsImage.formats || !cmsImage.formats[responsiveBreakPoints[i].size]) {
        allFormatAvailable = false
      }
    }

    if (allFormatAvailable) {
      attributes.srcset = ''
      attributes.sizes = ''

      for(let i = 0; i < responsiveBreakPoints.length; i++) {
        const responsive = responsiveBreakPoints[i]
        const image = cmsImage.formats[responsive.size]

        attributes.srcset += `${replaceBucketByCdn(image.url)} ${image.width}w, `

        if (i === responsiveBreakPoints.length - 1) {
          attributes.sizes += `${image.width}px, `
        } else {
          attributes.sizes += `(max-width: ${responsive.width}) ${image.width}px, `
        }
      }
    }
  }

  return attributes
}
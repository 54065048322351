<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="handleClick"
  >
    <path
      d="M15.1732 12.7069C15.9423 13.4956 15.9423 14.7734 15.1732 15.5622C14.4037 16.3514 13.1581 16.3507 12.3893 15.5622L7.875 10.9322L3.3607 15.5622C2.59121 16.3514 1.34558 16.3507 0.576783 15.5622C-0.192261 14.7734 -0.192261 13.4956 0.576783 12.7069L5.09108 8.07686L0.576783 3.44687C-0.192261 2.6581 -0.192261 1.38033 0.576783 0.591572C1.34583 -0.197191 2.59165 -0.197191 3.3607 0.591572L7.875 5.22157L12.3893 0.591572C13.1583 -0.197191 14.4042 -0.197191 15.1732 0.591572C15.9423 1.38033 15.9423 2.6581 15.1732 3.44687L10.6589 8.07686L15.1732 12.7069Z"
      fill="#272727"
    />
  </svg>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

export const debounce = (func, timeout = 200, that = this) => {
  let timer

  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(that, args)
    }, timeout)
  }
}

export const formatNumber = (number, significantDigits = 3, suffix = '') => {
  return (
    new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: significantDigits }).format(number) + suffix
  )
}

import { debounce } from '@/helpers/utils'
import { trackGA4Event } from '@/helpers/track'

export const trackSignUp = debounce(() => {
  trackGA4Event('sign_up')
}, 1000)

export const trackSignIn = debounce(() => {
  trackGA4Event('login')
}, 1000)

export const trackFinCreateSimulation = (city, postal_code) => {
  trackGA4Event('creation_simulation', { city, postal_code })
}
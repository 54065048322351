<template>
  <div class="swipe-container">
    <div v-if="scrollableLeft" class="scroll-arrow left" @click="scrollLeft">
      <uikit-chevron-icon type="left" />
    </div>
    <div v-if="scrollableRight" class="scroll-arrow right" @click="scrollRight">
      <uikit-chevron-icon type="right" />
    </div>
    <div ref="scrollContainer" class="scroll-container" @scroll="updateScroll">
      <div class="wrapper">
        <template v-for="item in items">
          <slot name="item" :item="item" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { UikitChevronIcon } from '@hz/ui-kit'
import { debounce } from '@/helpers/utils'

export default {
  components: {
    UikitChevronIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scrollableLeft: false,
      scrollableRight: false,
    }
  },
  created() {
    this.updateScroll = debounce(this.updateDebouncedScroll, 50)
  },
  mounted() {
    this.updateScroll()
  },
  methods: {
    updateDebouncedScroll() {
      this.scrollableLeft = this.$refs.scrollContainer.scrollLeft > 0
      this.scrollableRight =
        Math.round(this.$refs.scrollContainer.scrollWidth - this.$refs.scrollContainer.scrollLeft) !==
        this.$refs.scrollContainer.clientWidth
    },
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -this.$refs.scrollContainer.clientWidth })
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: this.$refs.scrollContainer.clientWidth })
    },
  },
}
</script>

<style lang="less" scoped>
.swipe-container {
  position: relative;

  .scroll-container {
    display: flex;
    flex-direction: row;

    margin-left: -32px;
    margin-right: -32px;
    padding-bottom: 26px;

    overflow-x: auto;
    scroll-behavior: smooth;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
  }
}

.scroll-arrow {
  @media @bp-sm-down {
    display: none;
  }

  position: absolute;
  width: 24px;
  height: 24px;
  color: var(--ds-color-primary-100);
  cursor: pointer;
  top: calc(50% - 26px);
  z-index: 1;

  &:before {
    width: 48px;
    height: 48px;
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--ds-color-white-100);
    border-radius: 100%;
    border: 2px solid var(--ds-color-gray-10);
  }

  &.left {
    left: 0;

    svg {
      margin-left: -2px;
    }
  }

  &.right {
    right: 0;

    svg {
      margin-left: 2px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

@media @bp-desktop {
  .scroll-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>

export function trackGA4Event(name, options = {}) {
  if (!import.meta.client || !window.dataLayer) return

  try {
    window.dataLayer?.push({
      event: name,
      ...options,
    })
  } catch (_) {
    // eslint-disable-next-line no-empty
  }
}

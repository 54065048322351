<template>
  <uikit-drawer id="drawer-navigation-menu">
    <template #header>
      <nuxt-link
        class="logo"
        no-prefetch
        to="/"
        @click="handleLogoClick"
      >
        <uikit-horiz-logo-icon />
      </nuxt-link>
    </template>

    <uikit-accordion :default-active-tab-id="items[0].id">
      <template
        v-for="item in items"
        :key="item.id"
      >
        <uikit-accordion-panel
          :id="item.id"
          :title="item.text"
          @panel-activated="handlePanelActivated"
        >
          <template
            v-for="(column, index) in item.columns"
            :key="`${item.id}-${index}`"
          >
            <layout-header-navigation-menu-panel-column :column="column" />
          </template>
        </uikit-accordion-panel>
      </template>
    </uikit-accordion>

    <template #footer>
      <template v-if="loggedIn">
        <NuxtLink
          no-prefetch
          :to="$config.public.appBaseUrl"
          class="u-mr-sm"
        >
          <uikit-button type="link">
            Accéder à l'application
          </uikit-button>
        </NuxtLink>
        <uikit-button
          type="link"
          aria-label="logout-button"
          @click="handleLogout"
        >
          <template #icon>
            <uikit-logout-icon />
          </template>
        </uikit-button>
      </template>
      <NuxtLink
        v-else
        no-prefetch
        :to="$config.public.appBaseUrl"
      >
        <uikit-button
          class="u-w-full"
          type="primary"
        >
          Connexion
        </uikit-button>
      </NuxtLink>
    </template>
  </uikit-drawer>
</template>

<script setup>
import {
  UikitButton,
  UikitDrawer,
  UikitHorizLogoIcon,
  UikitLogoutIcon,
  useDrawerStore,
  UikitAccordion,
  UikitAccordionPanel,
} from '@hz/ui-kit'
import { useAuthStore } from '~/stores/auth.js'

defineProps({
  items: {
    type: Array,
    required: true,
  },
})

const userStore = useAuthStore()

const attrs = useAttrs()
const loggedIn = computed(() => userStore.loggedIn)

const close = () => {
  useDrawerStore().removeDrawer({ id: attrs.id })
}

provide('closeMenuNow', close)

const handleLogoClick = () => {
  close()
}

const handlePanelActivated = (panelId) => {
  const sectionElement = document.getElementById(panelId)
  if (sectionElement) {
    setTimeout(() => sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' }), 1)
  }
}

const handleLogout = () => userStore.logout()
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  align-items: center;

  svg {
    height: 40px;
    width: 40px;
  }
}

:deep(.drawer-content-wrapper) {
  max-height: 100% !important;
  height: 100% !important;

  .drawer-content {
    padding-top: var(--ds-space-lg);
    border-top-left-radius: initial !important;
    border-top-right-radius: initial !important;

    .drawer-wrapper-body > .separator {
      display: none;
    }
  }

  .drawer-header {
    margin-bottom: -4px;
  }

  .drawer-body {
    border-top: 1px solid var(--ds-color-gray-25);
    padding-left: var(--ds-space-none);
    padding-right: var(--ds-space-none);
  }

  .drawer-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      position: absolute;
      top: -90px;
      display: block;
      height: 90px;
      width: 100%;
      pointer-events: none;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 12.64%, #fff 100%);
    }
  }
}

:deep(.uikit-accordion-panel) {
  .body {
    padding-top: var(--ds-space-lg);
    gap: var(--ds-space-3xl);
  }
}

:deep(.menu-panel-column) {
  max-width: initial;
}
</style>

<template>
  <menu
    :class="headerClasses"
    :style="menuStyle"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <div class="wrapper">
      <template
        v-for="item in items"
        :key="item.id"
      >
        <layout-header-navigation-menu-panel
          v-if="item.columns"
          v-show="activeMenuId === item.id"
          :item="item"
        />
      </template>
    </div>
  </menu>
</template>

<script setup>
defineProps({
  items: {
    type: Array,
    required: true,
  },
})

const activeMenuId = inject('activeMenuId', null)
const activeMenu = inject('activeMenu')

const headerClasses = computed(() => {
  return {
    active: activeMenuId.value,
    'with-special-encart': true,
  }
})

const menuStyle = computed(() => {
  return {
    maxHeight: activeMenuId.value ? '100vh' : '0px',
  }
})

const handleMouseOver = () => {
  activeMenu(activeMenuId.value, true)
}

const handleMouseLeave = () => {
  activeMenu(activeMenuId.value, false)
}
</script>

<style lang="less" scoped>
menu {
  .app-padding();

  position: fixed;
  top: 72px;

  display: flex;

  margin: 0;
  width: 100%;

  background-color: var(--ds-color-white-100);

  transition: max-height 0.3s ease;

  &.with-special-encart::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: ~'calc(var(--ds-gutter-xl) + max(0px, 100vw / 2 - var(--ds-container-xl) / 2))';
    background-color: var(--ds-color-primary-25);
    z-index: -1;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-grow: 1;
    max-width: var(--ds-container-xl);
    margin: 0 auto;
    opacity: 0;
  }

  &.active {
    border-bottom: 1px solid var(--ds-color-gray-25);

    .wrapper {
      transition: opacity 0.3s ease;
      transition-delay: 0.2s;
      opacity: 1;
    }
  }
}

@media @bp-desktop {
  menu {
    top: 80px;
  }
}
</style>

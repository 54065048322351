import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _pinia_pe3JoeNWe6 from "/app/plugins/_pinia.js";
import auth_client_E5M9zMdRCQ from "/app/plugins/auth.client.js";
import businessPartner_client_jTs76izTHY from "/app/plugins/businessPartner.client.js";
import gtm_client_KEcT5dOoKs from "/app/plugins/gtm.client.js";
import ui_kit_client_KWyzGFIsHR from "/app/plugins/ui-kit.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  _pinia_pe3JoeNWe6,
  auth_client_E5M9zMdRCQ,
  businessPartner_client_jTs76izTHY,
  gtm_client_KEcT5dOoKs,
  ui_kit_client_KWyzGFIsHR
]
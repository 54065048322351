<template>
  <div class="card" :class="{ 'no-shadow': noShadow }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noShadow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  display: flex;
  flex-direction: column;

  background: var(--ds-color-white-100);
  border-radius: 4px;

  overflow: hidden;

  &:not(.no-shadow) {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.1);
  }
}
</style>

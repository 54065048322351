import { defineStore } from 'pinia'

const sessionStorageStartAuthDateKey = 'start-auth-date'

function getNowTimeStamp() {
  return Math.floor(Date.now() / 1000)
}

export const useAuthStore = defineStore('home-auth', {
  state: () => ({
    loggedIn: false,
    userRegisteredLessThant30s: false,
    userSignInLessThant5min: false,
    user: {},
  }),
  getters: {
    isAdmin: (state) => {
      return state.user.isHorizAdmin ?? false
    },
    isSubscribed: (state) => {
      return state.user.plan !== 'free'
    },
    authenticated: () => {
      return import.meta.client ? window.keycloak.authenticated : false
    },
    token: () => {
      return import.meta.client ? window.keycloak.token : null
    },
    isPIN: (state) => {
      return state.user?.profiles && state.user.profiles['PIN'] === 'true'
    },
    isLoggedIn: (state) => {
      return state.loggedIn
    },
  },
  actions: {
    getKeycloakInstance() {
      return window.keycloak
    },
    async getToken() {
      if (import.meta.client) {
        await this.getKeycloakInstance().updateToken(70)

        return this.getKeycloakInstance().token
      } else {
        return Promise.resolve(null)
      }
    },
    setUser({ user, userRegisteredLessThant30s, userSignInLessThant5min }) {
      this.user = user
      this.setUserRegisteredInfo({ userRegisteredLessThant30s, userSignInLessThant5min })
    },
    setLoggedIn(loggedIn) {
      this.loggedIn = loggedIn
    },
    resetUserSignInformations() {
      this.setUserRegisteredInfo({ userRegisteredLessThant30s: false, userSignInLessThant5min: false })
    },
    setUserRegisteredInfo(userInfo) {
      this.userRegisteredLessThant30s = userInfo.userRegisteredLessThant30s
      this.userSignInLessThant5min = userInfo.userSignInLessThant5min
    },
    async register() {
      const url = new URL(window.location.href)
      sessionStorage.setItem(sessionStorageStartAuthDateKey, getNowTimeStamp().toString())
      await this.getKeycloakInstance().login({
        action: 'register',
        redirectUri: url.toString(),
      })
    },
    async login() {
      sessionStorage.setItem(sessionStorageStartAuthDateKey, getNowTimeStamp().toString())
      await this.getKeycloakInstance().login()
    },
    async logout() {
      sessionStorage.removeItem(sessionStorageStartAuthDateKey)
      await this.getKeycloakInstance().logout()
    },
  },
})

<template>
  <div
    class="header-wrapper"
    :class="headerClasses"
  >
    <header>
      <div class="wrapper">
        <nuxt-link
          class="logo"
          no-prefetch
          to="/"
        >
          <uikit-horiz-logo-icon class="u-hide-not-mobile" />
          <uikit-horiz-logo-full-icon class="u-hide-mobile full" />
        </nuxt-link>
        <div class="navigation-wrapper">
          <div
            ref="navigationContainer"
            class="navigation"
            @scroll="updateNavigationContainerScroll"
          >
            <layout-header-navigation
              :items="navigationItems"
              class="u-hide-mobile"
            />
          </div>
          <div
            v-if="scrollableLeft"
            class="hider left"
          />
          <div
            v-if="scrollableRight"
            class="hider right"
          />
        </div>

        <div class="user-menu u-hide-not-mobile">
          <uikit-button
            type="link"
            class="burger-menu"
            @click="handleMobileMenu"
          >
            <uikit-bars-icon />
          </uikit-button>
        </div>
        <div class="user-menu u-hide-mobile">
          <client-only>
            <template v-if="loggedIn">
              <uikit-dropdown class="user-menu-dropdown">
                <uikit-button type="link">
                  <uikit-avatar :size="32">
                    {{ userLogo }}
                  </uikit-avatar>
                  <template #right-icon>
                    <uikit-chevron-icon
                      type="down"
                      class="drop-icon"
                    />
                  </template>
                </uikit-button>
                <template #overlay>
                  <uikit-menu>
                    <NuxtLink
                      no-prefetch
                      :to="$config.public.appBaseUrl"
                    >
                      <uikit-menu-item id="go-to-app">
                        Accéder à l'application
                      </uikit-menu-item>
                    </NuxtLink>

                    <uikit-menu-item
                      id="logout"
                      @click="handleLogout"
                    >
                      Déconnexion
                    </uikit-menu-item>
                  </uikit-menu>
                </template>
              </uikit-dropdown>
            </template>
            <NuxtLink
              v-else
              no-prefetch
              :to="$config.public.appBaseUrl"
            >
              <uikit-button type="primary">
                Connexion
              </uikit-button>
            </NuxtLink>
            <template #fallback>
              <NuxtLink
                no-prefetch
                :to="$config.public.appBaseUrl"
              >
                <uikit-button type="primary">
                  Connexion
                </uikit-button>
              </NuxtLink>
            </template>
          </client-only>
        </div>
      </div>
    </header>
    <layout-header-navigation-menu :items="navigationItems" />
  </div>
</template>

<script setup>
import {
  UikitChevronIcon,
  UikitAvatar,
  UikitDropdown,
  UikitMenu,
  UikitMenuItem,
  UikitButton,
  UikitHorizLogoIcon,
  UikitHorizLogoFullIcon,
  UikitBarsIcon,
  useDrawerStore,
} from '@hz/ui-kit'
import { useAuthStore } from '~/stores/auth.js'
import { debounce } from '~/helpers/utils.js'
import { onBeforeUnmount } from 'vue'
import HeaderNavigationMenuMobileDrawer from './HeaderNavigationMenuMobileDrawer.vue'

const props = defineProps({
  header: {
    type: Object,
    default: () => ({ items: [] }),
  },
})

const activeMenuId = ref(null)
const navigationContainer = ref(null)
const scrollableLeft = ref(false)
const scrollableRight = ref(false)

const _updateNavigationContainerScroll = () => {
  scrollableLeft.value = navigationContainer.value.scrollLeft > 0
  scrollableRight.value =
    Math.round(navigationContainer.value.scrollWidth - navigationContainer.value.scrollLeft) !==
    navigationContainer.value.clientWidth
}
const updateNavigationContainerScroll = debounce(() => _updateNavigationContainerScroll(), 50)

const userStore = useAuthStore()
const route = useRoute()

const userLogo = computed(() => userStore.user?.username?.charAt(0)?.toUpperCase())

const navigationItems = computed(() => {
  return props.header?.items ?? []
})

const loggedIn = computed(() => userStore.loggedIn)
const headerClasses = computed(() => {
  return {
    'hover-elevate-z-index': route.meta?.headerWithHoverZIndex ?? false,
  }
})

const handleLogout = () => userStore.logout()

provide(
  'activeMenuId',
  computed(() => activeMenuId.value),
)

const hidePanel = debounce((menuId, show) => {
  activeMenuId.value = show ? menuId : null
}, 200)

const activeMenu = (menuId, show) => {
  if (show) {
    activeMenuId.value = menuId
    hidePanel(menuId, true)
  } else {
    hidePanel(menuId, false)
  }
}

const closeMenuNow = () => {
  activeMenuId.value = null
}

provide('activeMenu', activeMenu)
provide('closeMenuNow', closeMenuNow)

const handleMobileMenu = () => {
  useDrawerStore().addDrawer({
    id: 'drawer-navigation-menu',
    component: shallowRef(HeaderNavigationMenuMobileDrawer),
    props: {
      items: navigationItems.value,
    },
  })
}

onMounted(() => {
  _updateNavigationContainerScroll()
  window.addEventListener('resize', updateNavigationContainerScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateNavigationContainerScroll)
})
</script>

<style lang="less" scoped>
.header-wrapper {
  position: sticky;
  top: 0;

  z-index: 10;

  &.hover-elevate-z-index:hover {
    z-index: 20;
  }
}

header {
  .app-padding();

  display: flex;

  height: 72px;
  width: 100%;

  background-color: var(--ds-color-white-100);
  border-bottom: 1px solid var(--ds-color-gray-25);

  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    max-width: var(--ds-container-xl);
    margin: 0 auto;
  }

  .logo {
    display: flex;
    align-items: center;

    svg:not(.full) {
      height: 40px;
      width: 40px;
    }

    .brand-name {
      display: flex;
      text-wrap: nowrap;
      margin-left: var(--ds-space-md);

      color: var(--ds-color-gray-100);
      font-size: var(--ds-font-xl);
      font-weight: var(--ds-weight-semi-bold);
      line-height: normal;

      span {
        color: var(--ds-color-primary-100);
      }
    }
  }

  .navigation-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    height: 100%;

    .navigation {
      display: flex;

      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .hider {
      position: absolute;
      top: 0;
      display: block;
      width: 90px;
      height: 100%;
      pointer-events: none;

      &.left {
        left: -18px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 12.64%, #fff 100%);
      }

      &.right {
        right: -18px;
        background: linear-gradient(270deg, #fff 12.64%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  .user-menu {
    display: flex;
  }

  :deep(.burger-menu) {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    background: var(--ds-color-gray-10);
    color: var(--ds-color-gray-50) !important;

    &:hover {
      background: var(--ds-color-gray-10) !important;
      .btn-content {
        color: var(--ds-color-gray-50) !important;
      }
    }
  }
}

:deep(.user-menu-dropdown) {
  .drop-icon {
    margin-left: var(--ds-space-xs);
    color: var(--ds-color-gray-50);
  }
  .uikit-chevron-icon-down {
    transition: all 0.3s ease;
  }
  &.open {
    .uikit-chevron-icon-down {
      transform: rotate(180deg);
    }
  }
}

@media @bp-desktop {
  header {
    height: 80px;

    .wrapper {
      gap: var(--ds-space-4xl);
    }
  }
}
</style>

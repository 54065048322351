<template>
  <client-only>
    <UikitSmartBanner
      v-if="smartBanner"
      :app="smartBanner"
    />
  </client-only>
  <LayoutBaseLayout :name="layout">
    <div>
      <uikit-drawer-wrapper />
      <uikit-modal-wrapper />
      <NuxtPage />
    </div>
  </LayoutBaseLayout>
  <uikit-toaster />
</template>

<script setup>
import { UikitDrawerWrapper, UikitModalWrapper, UikitSmartBanner, UikitToaster } from '@hz/ui-kit'

const config = useRuntimeConfig()

const route = useRoute()

const layout = computed(() => route.meta.hzLayout)

const isGoodHumanGuy = ref(false)

onBeforeMount(() => {
  const intervalId = setInterval(() => {
    isGoodHumanGuy.value = !import.meta.server && (navigator?.userActivation?.hasBeenActive ?? false)
    if (isGoodHumanGuy.value) {
      clearInterval(intervalId)
    }
  }, 1000)
})

const smartBanner = computed(() => isGoodHumanGuy.value && route.meta.smartBanner)

useServerSeoMeta({
  title: 'Horiz.io',
  description: 'Faire un investissement rentable et piloter sa gestion locative.',
})

useHead({
  htmlAttrs: {
    lang: 'fr',
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover' },
  ],
  link: [
    {
      rel: 'preconnect',
      href: config.public.gtmUrl,
    },
    {
      rel: 'preconnect',
      href: config.public.kcBaseUrl,
    },
    {
      ...(config.public.dev || config.public.hpBaseUrl.includes('preprod')
        ? {
            rel: 'preconnect',
            href: 'https://horiz.io',
          }
        : {}),
    },
    {
      rel: 'preload',
      as: 'font',
      href: 'https://horiz.io/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2',
      type: 'font/woff2',
      crossorigin: true,
    },
    {
      rel: 'preload',
      as: 'image',
      href: '/imgs/logo-purple.svg',
    },
    { rel: 'icon', type: 'image/png', href: '/favicon.png' },
  ],
})
</script>

<style lang="less">
@import '../node_modules/@hz/ui-kit/src/styles/root.less';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  strong {
    font-weight: inherit;
    color: var(--ds-color-primary-100);
  }
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.hide,
.hide-on-mobile {
  display: none;
}

.u-color-primary-500 {
  color: var(--ds-color-primary-100);
}

a,
a:visited {
  color: inherit;
  text-decoration: none;

  &.cta-link {
    color: var(--ds-color-primary-100);
    font-weight: var(--ds-weight-semi-bold);
    cursor: pointer;
  }
}

a.no-link {
  color: inherit;
  text-decoration: none;
}

a.no-link-block:extend(a.no-link) {
  display: block;
}

// Zendesk hide on mobile
#launcher {
  display: none !important;
}

@media @bp-desktop {
  .hide-on-desktop {
    display: none;
  }

  .hide-on-mobile {
    display: revert;
  }

  // Zendesk visible on desktop
  #launcher {
    display: block !important;
  }
}

#wide-layout {
  .main {
    min-height: 100%;
  }
}

#default-layout {
  .main {
    .app-padding();
    min-height: 100%;
    max-width: @bp-desktop-xl-max-width;
    margin: auto;
  }

  @media @bp-desktop-xl {
    #banner-msg {
      margin-top: 36px;
    }
  }
}

.main {
  :deep(.uikit-btn) {
    font-weight: var(--ds-weight-semi-bold);
  }
}
</style>

<template>
  <Section class="agencies" wide-mode no-space>
    <h2 class="title">
      <slot name="title">
        Découvrir d’autres agences immobilières
        <template v-if="city">
          à
          <span>{{ city }}</span>
        </template>
      </slot>
    </h2>
    <article-swipe-container v-if="agencies.data.length" :items="agencies.data">
      <template #item="{ item }">
        <agency-card :key="item.id" :short="short" :agency="item" />
      </template>
    </article-swipe-container>
    <div v-else>
      <div class="info">Aucune agence immobilière n’est disponible pour le moment.</div>
    </div>
    <div>
      Vous êtes une agence ?
      <a class="cta-link" target="_blank" href="https://horiz.io/ressources/logiciel-agents-immobiliers">
        Ajouter mon agence à Horiz et multiplier mes ventes !
      </a>
    </div>
  </Section>
</template>

<script setup>
const props = defineProps({
  city: {
    type: String,
  },
  postalCode: {
    type: [Number, String],
  },
  excludeIds: {
    type: Array,
    default: () => [],
  },
  short: {
    type: Boolean,
    default: false,
  },
})

const agenciesApi = useAgenciesApi()

const query = computed(() => {
  const query = {
    published: true,
  }
  if (props.postalCode) {
    query['identity.postalCode'] = `${props.postalCode}`.length === 4 ? `0${props.postalCode}` : `${props.postalCode}`
    query['distance'] = 1
  }

  if (props.excludeIds.length) {
    query['excludeIds'] = props.excludeIds
  }

  return query
})

const { data: agencies } = await useAsyncData(() => agenciesApi.searchAgencies(query.value), {
  default: () => null,
})
</script>

<style lang="less" scoped>
:deep(.scroll-container) .wrapper {
  gap: 64px;
}

:deep(.section-container) {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.agencies {
  overflow: hidden;
  background: var(--ds-color-primary-25);

  h2.title {
    color: var(--ds-color-gray-100);
    font-size: 28px;
    font-style: normal;
    font-weight: var(--ds-weight-semi-bold);

    :deep(span) {
      color: var(--ds-color-primary-100);
    }
  }

  .info {
    color: var(--ds-color-gray-50);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--ds-weight-regular);
  }
}
</style>

import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (config.public.trackingEnabled && config.public.gtmContainerId !== '' && config.public.gtmUrl !== '') {
    nuxtApp.vueApp.use(createGtm({
      enabled: config.public.trackingEnabled,
      source: `${config.public.gtmUrl}/attacus-atlas.js`,
      id: config.public.gtmContainerId,
      defer: true,
      compatibility: false,
      debug: false,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
      trackViewEventProperty: 'nuxtRoute'
    }))
  }
})

<template>
  <div
    v-once
    ref="content"
    class="rich-text"
    v-html="html"
  />
</template>

<script setup>
import { replaceBucketByCdn } from '../../../helpers/strapi'

const props = defineProps({
  text: {
    type: String,
  },
})

const html = computed(() => replaceBucketByCdn(props.text).replaceAll('<img ', '<img loading="lazy"'))
</script>

<style lang="css" scoped>
:deep(> p) {
  margin-top: 0;
  margin-bottom: 0;
}
</style>

<template>
  <img
    :src="src"
    :alt="image.alternativeText"
    :height="imgHeight"
    :width="imgWidth"
    :class="{ 'fit-cover': fitCover }"
    class="cms-image"
    v-bind="attributes"
  />
</template>

<script>
/*
  Strapi breakpoint sizes
  - iconsmall: 46, // Icon: 46 x 46 (small)
  - iconlarge: 140, // Icon: 140 x 140 (large)
  - xsmall: 260, // Card (small 260 x 180)
  - small: 364, // Card (large 364 x 180) + Cover (mobile 348 x 256)
  - medium: 390, // Article (50% 390 x 260)
  - cover: 500, // Cover: (desktop 500 x 368)
  - large: 780, // Article (100% 780 x 520)
*/

import { getResponsiveAttributes, replaceBucketByCdn } from '../../../../helpers/strapi'

function validateImageFormat(valueToValidate) {
  return ['iconsmall', 'iconlarge', 'xsmall', 'thumbnail', 'small', 'medium', 'cover', 'large'].includes(
    valueToValidate,
  )
}

export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      validator(value) {
        return validateImageFormat(value)
      },
      default: 'medium',
    },
    width: {
      type: [String, Number],
      default: undefined,
    },
    height: {
      type: [String, Number],
      default: undefined,
    },
    fitCover: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Array,
      default: () => [],
      validator(value) {
        for (let i = 0; i < value.length; i++) {
          if (!validateImageFormat(value[i].size)) {
            return false
          }
        }

        return true
      },
    },
    noLazy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageToUse() {
      return this.image.image.formats && this.image.image.formats[this.size]
        ? this.image.image.formats[this.size]
        : this.image.image
    },
    imgWidth() {
      return this.width ? this.width : this.imageToUse.width
    },
    imgHeight() {
      return this.height ? this.height : this.imageToUse.height
    },
    src() {
      return replaceBucketByCdn(this.imageToUse.url)
    },
    attributes() {
      const attributes = getResponsiveAttributes(this.responsive, this.image.image)

      if (!this.noLazy) {
        attributes.loading = 'lazy'
      }

      return attributes
    },
  },
}
</script>

<style lang="less" scoped>
.fit-cover {
  object-fit: cover;
}
</style>

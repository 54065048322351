<template>
  <div class="menu-panel-column">
    <div
      v-if="column.title"
      class="title"
    >
      {{ column.title }}
    </div>
    <div class="sub-items">
      <template
        v-for="subItem in subItems"
        :key="subItem.id"
      >
        <cms-components-shared-link
          :link="subItem"
          type="no-style"
          class="sub-item"
          @link-click="handleSubMenuClick"
        >
          <div
            v-if="subItem.icon"
            class="icon-wrapper"
          >
            <div
              class="icon"
              v-html="subItem.icon"
            />
          </div>
          <div class="content">
            <div class="sub-title">
              {{ subItem.title }}
            </div>
            <div
              v-if="subItem.description"
              class="description"
            >
              {{ subItem.description }}
            </div>
          </div>
        </cms-components-shared-link>
      </template>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  column: {
    type: Object,
    required: true,
  },
})

const subItems = computed(() =>
  props.column.subItems.map((subItem) => ({
    ...subItem,
    text: undefined,
    title: subItem.text,
  })),
)

const closeMenuNow = inject('closeMenuNow')

const handleSubMenuClick = () => {
  closeMenuNow()
}
</script>

<style lang="less" scoped>
.menu-panel-column {
  display: flex;
  flex-direction: column;
  flex: 1 1 33%;
  max-width: 33%;
  gap: var(--ds-space-lg);

  .title {
    color: var(--ds-color-gray-50);
    font-size: var(--ds-font-xs);
    font-weight: var(--ds-weight-medium);
    text-transform: uppercase;
  }

  .sub-items {
    display: flex;
    flex-direction: column;
    gap: var(--ds-space-2lg);
  }

  :deep(.sub-item) > div {
    display: flex;
    flex-direction: row;
    gap: var(--ds-space-lg);
  }

  .sub-item {
    transition: opacity 0.3s ease;

    .icon-wrapper {
      .icon {
        width: 24px;
        height: 24px;
        color: var(--ds-color-gray-100);

        :deep(svg) {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .sub-title {
        color: var(--ds-color-gray-100);
        font-size: var(--ds-font-md);
        font-weight: var(--ds-weight-semi-bold);
        padding-bottom: var(--ds-space-xs);
      }
      .description {
        color: var(--ds-color-gray-50);
        font-size: var(--ds-font-sm);
        font-weight: var(--ds-weight-regular);
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>

import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('home-notification', {
  state: () => ({
    queue: [],
    cpt: 0,
  }),
  actions: {
    pushUnique(componentName) {
      if (!this.queue.find((element) => element.name === componentName)) {
        this.push(componentName)
      }
    },
    push(componentName) {
      this.push_mutation(componentName)
    },
    push_mutation(componentName) {
      this.queue = [
        ...this.queue,
        {
          name: componentName,
          id: componentName + '-' + this.cpt,
        },
      ]
      this.cpt++
    },
    remove(componentId) {
      this.queue = this.queue.filter((elem) => elem.id != componentId)
    },
  },
})

<template>
  <NuxtLink class="u-text-decoration-none" no-prefetch :to="agencyPageUrl">
    <article-card-generic class="agency-card" :class="{ short }">
      <div class="logo-container">
        <img
          width="48"
          height="48"
          class="logo"
          :alt="`Logo de ${agency.identity.companyName}`"
          :src="agency.logo"
        />
      </div>
      <div class="infos">
        <div class="title">Agence {{ agency.identity.companyName }}</div>
        <div v-if="!short" class="cta">
          Découvrir l’agence
          <uikit-arrow-thin-icon class="u-ml-sm icon" type="right" />
        </div>
      </div>
    </article-card-generic>
  </NuxtLink>
</template>

<script setup>
import { UikitArrowThinIcon } from '@hz/ui-kit'
const props = defineProps({
  agency: {
    type: Object,
    required: true,
  },
  short: {
    type: Boolean,
    default: false,
  },
})

const agencyPageUrl = computed(() => {
  let cp = `${props.agency.identity.postalCode}`

  if (cp.length === 4) {
    cp = `0${cp}`
  }

  const city = encodeURIComponent(props.agency.identity.city).replace(/[!'()*]/g, escape)

  return `/ou-investir/villes/${city}/${cp}/agences/${props.agency.slug}/${props.agency.id}`
})
</script>

<style lang="less" scoped>
:deep(.card) {
  flex-direction: row !important;
}

.agency-card {
  gap: 12px;
  padding: 40px 32px;
  width: 450px;
  height: 142px;

  &.short {
    min-width: 216px;
    max-width: 280px;
    width: fit-content;
    height: 160px;
    padding: 20px 16px;
    flex-direction: column !important;
    align-items: center;
  }

  .logo {
    border-radius: 48px;
  }

  .infos {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;
  }

  .title {
    color: var(--ds-color-gray-100);
    font-size: 18px;
    font-style: normal;
    font-weight: var(--ds-weight-semi-bold);
  }

  .cta {
    display: flex;
    align-items: center;
    margin: 0 auto;
    color: var(--ds-color-primary-100);
    font-size: 16px;
    font-style: normal;
    font-weight: var(--ds-weight-semi-bold);
  }

  .icon {
    height: 16px;
    width: 16px;
  }
}
</style>

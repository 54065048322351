<template>
  <nuxt-link
    :to="to"
    :title="link.title ?? ''"
    v-bind="attributes"
    no-prefetch
    class="cms-link"
    :class="{ ext: !isLocalLink }"
  >
    <template v-if="type === 'no-style'">
      <template v-if="defaultSlotExists">
        <div @click="handleClick">
          <slot />
        </div>
      </template>
      <template v-else>
        <div @click="handleClick">
          {{ link.text }}
        </div>
      </template>
    </template>
    <uikit-button
      v-else
      :type="type === 'button' ? 'primary' : 'link'"
      @click="handleClick"
    >
      <template v-if="defaultSlotExists">
        <slot />
      </template>
      <template v-else>
        {{ link.text }}
      </template>
      <template
        v-if="$slots['right-icon']"
        #right-icon
      >
        <slot name="right-icon" />
      </template>
    </uikit-button>
  </nuxt-link>
</template>

<script>
import { UikitButton } from '@hz/ui-kit'
const ENV_DOMAINS = ['horiz.io', 'dev.horiz.io', 'preprod.horiz.io']
const RESERVED_PATH_NAME = ['^/annonces/+', '^/rentabilite/+', '^/ressources/+', '^/rendement-locatif/+']

export default {
  components: {
    UikitButton,
  },
  props: {
    type: {
      type: String,
      default: 'link',
      validator: (value) => ['link', 'button', 'no-style'].includes(value),
    },
    link: {
      type: Object,
      required: true,
    },
    displayAsButton: {
      type: Boolean,
      default: false,
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['link-click'],
  computed: {
    defaultSlotExists() {
      return !!this.$slots.default
    },
    url() {
      return new URL(this.link.link)
    },
    linkStartBySlash() {
      return this.link.link.startsWith('/')
    },
    isLocalLink() {
      if (this.linkStartBySlash) {
        return !this.isReservedPath(this.link.link)
      }

      return ENV_DOMAINS.includes(this.url.hostname) && !this.isReservedPath(this.url.pathname)
    },
    to() {
      if (!this.isLocalLink) {
        return this.link.link
      }

      if (this.linkStartBySlash) {
        return this.link.link
      }

      return this.url.pathname + this.url.search
    },
    attributes() {
      const attributes = {}

      if (this.link.noIndex) {
        attributes.rel = 'noindex'
      }

      if (this.link.openInNewTab) {
        attributes.target = '_blank'
      }

      return attributes
    },
  },
  methods: {
    test(pattern, pathname) {
      const regex = new RegExp(pattern)

      return regex.test(pathname)
    },
    isReservedPath(pathname) {
      for (const index in RESERVED_PATH_NAME) {
        if (this.test(RESERVED_PATH_NAME[index], pathname)) {
          return true
        }
      }

      return false
    },
    handleClick() {
      this.$emit('link-click')
    },
  },
}
</script>

import { default as indexxgHKLKJylnMeta } from "/app/pages/ou-investir/index.vue?macro=true";
import { default as indexVSNV1qVZ1zMeta } from "/app/pages/biens-rentables/index.vue?macro=true";
import { default as index4sMNA39jx9Meta } from "/app/pages/[parentArticleCategory]/[articleCategoryOrArticle]/index.vue?macro=true";
import { default as _91page_93oQKem42a4YMeta } from "/app/pages/[parentArticleCategory]/[articleCategoryOrArticle]/page/[page].vue?macro=true";
import { default as indexoGYMc0MCK9Meta } from "/app/pages/[parentArticleCategory]/index.vue?macro=true";
import { default as gestion_45locativebRKRZfVwFzMeta } from "/app/pages/gestion-locative.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as offrescnpGmzj4tCMeta } from "/app/pages/offres.vue?macro=true";
import { default as _91id_93Z8b3SUp5TUMeta } from "/app/pages/ou-investir/annonces/[id].vue?macro=true";
import { default as _91page_930i1j6dKirAMeta } from "/app/pages/ou-investir/page/[page].vue?macro=true";
import { default as _91id_93lUdwCEx9YlMeta } from "/app/pages/ou-investir/villes/[city]/[cp]/agences/[slug]/[id].vue?macro=true";
import { default as indexxnFQJdT5W9Meta } from "/app/pages/ou-investir/villes/[city]/[cp]/index.vue?macro=true";
import { default as _91_46_46_46slug_93Hwb2t66loSMeta } from "/app/pages/preview/[...slug].vue?macro=true";
import { default as rendement_45locatifaUfBstbMdkMeta } from "/app/pages/rendement-locatif.vue?macro=true";
export default [
  {
    name: indexxgHKLKJylnMeta?.name ?? "ou-investir-page",
    path: indexxgHKLKJylnMeta?.path ?? "/ou-investir/page/:page",
    meta: { ...(indexxgHKLKJylnMeta || {}), ...{"hzLayout":"wide","smartBanner":"PERFORMANCE_ALERTS"} },
    alias: indexxgHKLKJylnMeta?.alias || [],
    redirect: indexxgHKLKJylnMeta?.redirect,
    component: () => import("/app/pages/ou-investir/index.vue").then(m => m.default || m)
  },
  {
    name: indexVSNV1qVZ1zMeta?.name ?? "biens-rentables-category-facet",
    path: indexVSNV1qVZ1zMeta?.path ?? "/biens-rentables/:category/:city/:postalCode",
    meta: { ...(indexVSNV1qVZ1zMeta || {}), ...{"hzLayout":"wide"} },
    alias: indexVSNV1qVZ1zMeta?.alias || [],
    redirect: indexVSNV1qVZ1zMeta?.redirect,
    component: () => import("/app/pages/biens-rentables/index.vue").then(m => m.default || m)
  },
  {
    name: indexVSNV1qVZ1zMeta?.name ?? "biens-rentables-category",
    path: indexVSNV1qVZ1zMeta?.path ?? "/biens-rentables/:category",
    meta: { ...(indexVSNV1qVZ1zMeta || {}), ...{"hzLayout":"wide"} },
    alias: indexVSNV1qVZ1zMeta?.alias || [],
    redirect: indexVSNV1qVZ1zMeta?.redirect,
    component: () => import("/app/pages/biens-rentables/index.vue").then(m => m.default || m)
  },
  {
    name: indexVSNV1qVZ1zMeta?.name ?? "biens-rentables-custom-search",
    path: indexVSNV1qVZ1zMeta?.path ?? "/biens-rentables/search",
    meta: { ...(indexVSNV1qVZ1zMeta || {}), ...{"hzLayout":"wide"} },
    alias: indexVSNV1qVZ1zMeta?.alias || [],
    redirect: indexVSNV1qVZ1zMeta?.redirect,
    component: () => import("/app/pages/biens-rentables/index.vue").then(m => m.default || m)
  },
  {
    name: index4sMNA39jx9Meta?.name ?? "parentArticleCategory-articleCategoryOrArticle",
    path: index4sMNA39jx9Meta?.path ?? "/:parentArticleCategory()/:articleCategoryOrArticle()",
    meta: { ...(index4sMNA39jx9Meta || {}), ...{"hzLayout":"wide"} },
    alias: index4sMNA39jx9Meta?.alias || [],
    redirect: index4sMNA39jx9Meta?.redirect,
    component: () => import("/app/pages/[parentArticleCategory]/[articleCategoryOrArticle]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93oQKem42a4YMeta?.name ?? "parentArticleCategory-articleCategoryOrArticle-page-page",
    path: _91page_93oQKem42a4YMeta?.path ?? "/:parentArticleCategory()/:articleCategoryOrArticle()/page/:page()",
    meta: { ...(_91page_93oQKem42a4YMeta || {}), ...{"hzLayout":"wide"} },
    alias: _91page_93oQKem42a4YMeta?.alias || [],
    redirect: _91page_93oQKem42a4YMeta?.redirect,
    component: () => import("/app/pages/[parentArticleCategory]/[articleCategoryOrArticle]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexoGYMc0MCK9Meta?.name ?? "parentArticleCategory",
    path: indexoGYMc0MCK9Meta?.path ?? "/:parentArticleCategory()",
    meta: { ...(indexoGYMc0MCK9Meta || {}), ...{"hzLayout":"wide"} },
    alias: indexoGYMc0MCK9Meta?.alias || [],
    redirect: indexoGYMc0MCK9Meta?.redirect,
    component: () => import("/app/pages/[parentArticleCategory]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVSNV1qVZ1zMeta?.name ?? "biens-rentables",
    path: indexVSNV1qVZ1zMeta?.path ?? "/biens-rentables",
    meta: { ...(indexVSNV1qVZ1zMeta || {}), ...{"hzLayout":"wide"} },
    alias: indexVSNV1qVZ1zMeta?.alias || [],
    redirect: indexVSNV1qVZ1zMeta?.redirect,
    component: () => import("/app/pages/biens-rentables/index.vue").then(m => m.default || m)
  },
  {
    name: gestion_45locativebRKRZfVwFzMeta?.name ?? "gestion-locative",
    path: gestion_45locativebRKRZfVwFzMeta?.path ?? "/gestion-locative",
    meta: { ...(gestion_45locativebRKRZfVwFzMeta || {}), ...{"hzLayout":"wide"} },
    alias: gestion_45locativebRKRZfVwFzMeta?.alias || [],
    redirect: gestion_45locativebRKRZfVwFzMeta?.redirect,
    component: () => import("/app/pages/gestion-locative.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: { ...(indexN6pT4Un8hYMeta || {}), ...{"hzLayout":"wide","smartBanner":"PERFORMANCE_ALERTS"} },
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: offrescnpGmzj4tCMeta?.name ?? "offres",
    path: offrescnpGmzj4tCMeta?.path ?? "/offres",
    meta: { ...(offrescnpGmzj4tCMeta || {}), ...{"hzLayout":"default"} },
    alias: offrescnpGmzj4tCMeta?.alias || [],
    redirect: offrescnpGmzj4tCMeta?.redirect,
    component: () => import("/app/pages/offres.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8b3SUp5TUMeta?.name ?? "ou-investir-annonces-id",
    path: _91id_93Z8b3SUp5TUMeta?.path ?? "/ou-investir/annonces/:id()",
    meta: { ...(_91id_93Z8b3SUp5TUMeta || {}), ...{"hzLayout":"wide"} },
    alias: _91id_93Z8b3SUp5TUMeta?.alias || [],
    redirect: _91id_93Z8b3SUp5TUMeta?.redirect,
    component: () => import("/app/pages/ou-investir/annonces/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxgHKLKJylnMeta?.name ?? "ou-investir",
    path: indexxgHKLKJylnMeta?.path ?? "/ou-investir",
    meta: { ...(indexxgHKLKJylnMeta || {}), ...{"hzLayout":"wide","smartBanner":"PERFORMANCE_ALERTS"} },
    alias: indexxgHKLKJylnMeta?.alias || [],
    redirect: indexxgHKLKJylnMeta?.redirect,
    component: () => import("/app/pages/ou-investir/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_930i1j6dKirAMeta?.name ?? "ou-investir-page-page",
    path: _91page_930i1j6dKirAMeta?.path ?? "/ou-investir/page/:page()",
    meta: { ...(_91page_930i1j6dKirAMeta || {}), ...{"hzLayout":"wide"} },
    alias: _91page_930i1j6dKirAMeta?.alias || [],
    redirect: _91page_930i1j6dKirAMeta?.redirect,
    component: () => import("/app/pages/ou-investir/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _91id_93lUdwCEx9YlMeta?.name ?? "ou-investir-villes-city-cp-agences-slug-id",
    path: _91id_93lUdwCEx9YlMeta?.path ?? "/ou-investir/villes/:city()/:cp()/agences/:slug()/:id()",
    meta: { ...(_91id_93lUdwCEx9YlMeta || {}), ...{"hzLayout":"wide"} },
    alias: _91id_93lUdwCEx9YlMeta?.alias || [],
    redirect: _91id_93lUdwCEx9YlMeta?.redirect,
    component: () => import("/app/pages/ou-investir/villes/[city]/[cp]/agences/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxnFQJdT5W9Meta?.name ?? "ou-investir-villes-city-cp",
    path: indexxnFQJdT5W9Meta?.path ?? "/ou-investir/villes/:city()/:cp()",
    meta: { ...(indexxnFQJdT5W9Meta || {}), ...{"hzLayout":"wide"} },
    alias: indexxnFQJdT5W9Meta?.alias || [],
    redirect: indexxnFQJdT5W9Meta?.redirect,
    component: () => import("/app/pages/ou-investir/villes/[city]/[cp]/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Hwb2t66loSMeta?.name ?? "preview-slug",
    path: _91_46_46_46slug_93Hwb2t66loSMeta?.path ?? "/preview/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93Hwb2t66loSMeta || {}), ...{"hzLayout":"wide"} },
    alias: _91_46_46_46slug_93Hwb2t66loSMeta?.alias || [],
    redirect: _91_46_46_46slug_93Hwb2t66loSMeta?.redirect,
    component: () => import("/app/pages/preview/[...slug].vue").then(m => m.default || m)
  },
  {
    name: rendement_45locatifaUfBstbMdkMeta?.name ?? "rendement-locatif",
    path: rendement_45locatifaUfBstbMdkMeta?.path ?? "/rendement-locatif",
    meta: { ...(rendement_45locatifaUfBstbMdkMeta || {}), ...{"hzLayout":"wide","smartBanner":"PERFORMANCE_ALERTS"} },
    alias: rendement_45locatifaUfBstbMdkMeta?.alias || [],
    redirect: rendement_45locatifaUfBstbMdkMeta?.redirect,
    component: () => import("/app/pages/rendement-locatif.vue").then(m => m.default || m)
  }
]
import { hooksFetchPost } from '../core/api'

export const usePaymentApi = () => ({
  setPromoCode: async (pcAttempt) =>
    await hooksFetchPost('/v1/payment/promotion-code', {
      body: {
        promotionCode: pcAttempt,
      },
    }),
})

import { createPinia, setActivePinia } from 'pinia'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const pinia = createPinia()
  nuxtApp.vueApp.use(pinia)
  setActivePinia(pinia)

  if (import.meta.server) {
    nuxtApp.payload.pinia = pinia.state.value
  } else if (nuxtApp.payload && nuxtApp.payload.pinia) {
    // Hydrate with current screen width
    nuxtApp.payload.pinia['uikit-screen-size'].windowWidth = window.innerWidth
    pinia.state.value = nuxtApp.payload.pinia
  }

  return {
    provide: {
      pinia,
    },
  }
})

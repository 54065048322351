import { hooksFetch, hooksFetchPost } from '../core/api'

export const useAgenciesApi = () => ({
  searchAgencies: async (query) => {
    return await hooksFetch('/v1/agencies', { query: { filter: query } })
  },
  getAgencyById: async (id) => {
    return await hooksFetch(`/v1/agencies/${id}`)
  },
  sendAdContactForm: async (id, body) => {
    return await hooksFetchPost(`/v1/agencies/${id}/contact`, { body })
  },
  sendReContactMeForm: async (id, body) => {
    return await hooksFetchPost(`/v1/agencies/${id}/re-contact-me`, { body })
  },
  getAgencyPhone: async (id, body) => {
    return await hooksFetchPost(`/v1/agencies/${id}/phone`, { body })
  },
})

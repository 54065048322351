import { useAuthStore } from '../stores/auth'

async function _fetch(baseURL, url, options = {}) {
  const authStore = useAuthStore()

  return $fetch(url, {
    baseURL: baseURL,
    onRequest: async ({ options }) => {
      options.headers = options.headers || {}

      if (authStore.authenticated) {
        const token = await authStore.getToken()
        options.headers['Authorization'] = `Bearer ${token}`
      } else {
        delete options.headers['Authorization']
      }

      options.credentials = 'include'
    },
    ...options,
  })
}

export const horizFetch = async (url, options = {}) => {
  const config = useRuntimeConfig()

  return await _fetch(config.public.apiUrl, url, options)
}

export async function horizFetchPost(url, options = {}) {
  return await horizFetch(url, {
    method: 'POST',
    ...options,
  })
}

export const hooksFetch = async (url, options = {}) => {
  const config = useRuntimeConfig()

  return await _fetch(config.public.hooksApiUrl, url, options)
}

export async function hooksFetchPost(url, options = {}) {
  return await hooksFetch(url, {
    method: 'POST',
    ...options,
  })
}

export async function hooksFetchPatch(url, options = {}) {
  return await hooksFetch(url, {
    method: 'PATCH',
    ...options,
  })
}

export async function hooksFetchPut(url, options = {}) {
  return await hooksFetch(url, {
    method: 'PUT',
    ...options,
  })
}

export async function hooksFetchDelete(url, options = {}) {
  return await hooksFetch(url, {
    method: 'DELETE',
    ...options,
  })
}

<template>
  <div :id="layoutId" tabindex="-1">
    <LazyBannerMessage v-if="$config.public.discountCode.length" />
    <slot name="head-banner" />
    <layout-header :header="commonComps.header" />
    <div class="main">
      <slot />
    </div>
    <nuxt-lazy-hydrate when-visible>
      <lazy-layout-footer :footer="commonComps.footer" />
    </nuxt-lazy-hydrate>
    <lazy-notifications v-if="queue.length" />
    <ZenDeskButton />
  </div>
</template>

<script setup>
import { useScreenSizeStore } from '@hz/ui-kit'

const cmsApi = useCmsApi()
const screenStore  = useScreenSizeStore() // DO NOT REMOVE

const { data: commonComps } = await useLazyAsyncData(
  'common-components',
  () => cmsApi.getCommonComponents(),
  {
    default: () => ({
      header: { items: [] },
      footer: { sections: [] },
    }),
  },
)
</script>

<script>
import { trackSignIn, trackSignUp } from '@/helpers/conversion/conversion'
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '../../stores/auth'
import { useNotificationStore } from '../../stores/notification'
import CouponNotification from '../Notifications/CouponNotification'

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useAuthStore, ['loggedIn', 'userRegisteredLessThant30s', 'userSignInLessThant5min']),
    ...mapState(useNotificationStore, ['queue']),
    layoutId() {
      return `${this.name}-layout`
    },
  },
  async mounted() {
    if (import.meta.client) {
      let promotionCodeKeyName = this.$route.query.coupon
      if (this.$config.public.discountCode.length) {
        promotionCodeKeyName = this.$config.public.discountCode
      }
      if (promotionCodeKeyName && promotionCodeKeyName.length) {
        const paymentApi = usePaymentApi()
        const returnData = await paymentApi.setPromoCode(promotionCodeKeyName)

        if (returnData && returnData.valid) {
          this.pushUnique(shallowRef(CouponNotification))
        }
      }
      await this.trackConversions()
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['resetUserSignInformations']),
    ...mapActions(useNotificationStore, ['pushUnique']),
    async trackConversions() {
      if (this.loggedIn) {
        const userActionHistoryApi = useUserActionHistoryApi()
        const userHistory = await userActionHistoryApi.get()
        const signUpEventIsTracked = !!userHistory.history?.signUpEventIsTracked

        if (!signUpEventIsTracked && this.userRegisteredLessThant30s) {
          trackSignUp()
          await userActionHistoryApi.flagActionAsDone('signUpEventIsTracked')
        }
        if (this.userSignInLessThant5min) {
          trackSignIn()
        }
        this.resetUserSignInformations()
      }
    },
  },
}
</script>
